import type { PageProps } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import Newsletter from 'src/components/sections/Newsletter'
import NavBar from 'src/components/common/Institutional/CallCenter'

// import { Container } from './styles';

const CentralDeAtendimento: FCC<PageProps> = (props) => {
  const {
    location: { host, pathname },
  } = props

  const title =
    'Central de Atendimento - Sex Shop e Lingerie: Atacado e Varejo | Miess'

  const docTitle = 'Central de Atendimento'

  const slug = 'institucionais'

  const description =
    'Encontre as melhores Lingeries e Produtos de Sex Shop Atacado e Varejo. Conheça a loja mais completa de produtos eróticos do Brasil.'

  const canonical =
    host !== undefined
      ? `https://${host}/${slug}`
      : slug
      ? `/${slug}`
      : pathname

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />

      {/* Sections */}

      <Breadcrumb
        name={docTitle}
        breadcrumbList={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />
      {/* <NavBar /> */}
      <NavBar />
      <Newsletter />
    </>
  )
}

export default CentralDeAtendimento
